#timer {
  background: black;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: monospace;
  font-size: 30px;
}

@media (min-width: 768px){
  #timer{
    font-size: 70px;
  }
}

